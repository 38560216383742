body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* index.css */
button.default-btn {
  margin-top: 20px;
  border-radius: 10px;
  background: var(--Gradient-1, linear-gradient(93deg, #AC2582 -18.36%, #460F35 183.89%));
  color: white;
  border: none; /* Remove any default border */
}

button.default-btn:hover {
  background: var(--Gradient-1, linear-gradient(93deg, #AC2582 -18.36%, #460F35 183.89%));
}
.animated-img {
  transition: transform 0.3s ease-in-out;
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.animated-img:hover {
  transform: scale(1.1);
}

/* Glassmorphism card effect */
.Glassmorphism-card {
  /* backdrop-filter: blur(3px) saturate(149%); */
  -webkit-backdrop-filter: blur(3px) saturate(100%);
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
  -webkit-box-shadow: 38px 34px 62px -33px rgba(0,0,0,0.58);
-moz-box-shadow: 38px 34px 62px -33px rgba(0,0,0,0.58);
box-shadow: 38px 34px 62px -33px rgba(0,0,0,0.58);

}

.UserPageTitleStyle {
  font-family: 'Myanmar Sagar', 'Heading', sans-serif;
  font-size: 20px;
  font-weight: 500;
  color:'#CC3A35'
}

.CodeStyle {
  font-family: 'Myanmar Sagar', 'Heading', sans-serif;
  font-weight: 700;
  font-size: 1rem;
  color: #5B1144;
  margin-right: 2%;
}
