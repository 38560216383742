/* App.css */

html, body, #root, .App {
  height: 100%;
  margin: 0;
  /* background-color: #e3e1f7; */
}

.App-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 7%;
}

.App-header img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
.custom-input {
  width: 463px;
  height: 37px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.custom-input:focus {
  border-color: var(--primary-color);
  outline: none;
}
.input-label{
  font-size: 16px;
  height: 200;
}

.gradient-text {
  background-image: linear-gradient(45deg, #AC2582, #460F35);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.gradient-text-more {
  background-image: linear-gradient(30deg, #c8302d 2%, #460F35 98%);;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.gradient-text-another {
  background-image: linear-gradient(183deg, rgba(190,136,79,1) 0%, rgba(200,47,45,1) 38%, rgba(91,17,68,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}